import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import DashboardKpi from "./Dashboard/DashboardKpi";
import kpiModel from "models/kpi.model";
import dashboardModel from "models/dashboard.model.js";
import { helper } from "lib/helper";
import { useEffect, useState } from "react";
const Header = () => {
  const [driverCount, setDriverCount] = useState(0);
  const [supportCount, setSupportCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [totalRide, setTotalRide] = useState(0);
  const [todaysBooking, setTodaysBooking] = useState(0);
  const [onlineDrivers, setOnlineDrivers] = useState(0);
  const [newDrivers, setNewDrivers] = useState(0);
  const [totalIncomeAmount, setTotalIncomeAmount] = useState(0);
  const [todayIncomeAmount, setTodayIncomeAmount] = useState(0);
  const [totalMalikPending, setTotalMalikPending] = useState(0);
  const [todaysMalikPending, setTodaysMalikPending] = useState(0);
  const [borewellComission, setBorewellCommision] = useState(0);

  const getDashboardKpi = async () => {
    await kpiModel
      .dashboardKpi()
      .then(result => {
        // console.log(result);

        setSupportCount(result?.data?.supportCount);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const getDashboardData = async () => {
    await dashboardModel
      .dashboardData()
      .then(result => {
        setDriverCount(result?.data?.driver_count);
        // setSupportCount(result?.data?.supportCount);
        setUserCount(result?.data?.user_count);
        setTotalRide(result?.data?.totalBooking);
        setTodaysBooking(result?.data?.todaysBooking);
        setOnlineDrivers(result?.data?.onlineDrivers);
        setNewDrivers(result?.data?.newDrivers);
        setTotalIncomeAmount(result?.data?.totalIncomeAmount);
        setTodayIncomeAmount(result?.data?.todayIncomeAmount);
        setTotalMalikPending(result?.data?.totalMalikPending);
        setBorewellCommision(result?.data?.borewellCommision);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDashboardKpi();
    getDashboardData();
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <DashboardKpi
                kpiTitle="Total rides"
                kpiValue={totalRide}
                kpiIcon="fas fa-route"
                iconBakgroundColor="bg-success"
                navigateUrl="/admin/ride"
              />
              <DashboardKpi
                kpiTitle="Today's rides"
                kpiValue={todaysBooking}
                kpiIcon="fas fa-route"
                iconBakgroundColor="bg-primary"
              />
              <DashboardKpi
                kpiTitle="Drivers"
                kpiValue={driverCount}
                kpiIcon="fas fa-user-astronaut"
                iconBakgroundColor="bg-warning"
                navigateUrl="/admin/drivers"
              />
              <DashboardKpi
                kpiTitle="Users"
                kpiValue={userCount}
                kpiIcon="fas fa-users"
                iconBakgroundColor="bg-info"
                navigateUrl="/admin/users"
              />
            </Row>
            <Row className="mt-2">
              <DashboardKpi
                kpiTitle="Message"
                kpiValue={supportCount}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              />
              <DashboardKpi
                kpiTitle="Active Drivers"
                kpiValue={onlineDrivers}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-red"
                // navigateUrl="/admin/messages"
              />
              <DashboardKpi
                kpiTitle="New drivers"
                kpiValue={newDrivers}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-red"
                navigateUrl="/admin/messages"
              />
              <DashboardKpi
                kpiTitle="Total Income Amount"
                kpiValue={totalIncomeAmount}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              />
            </Row>
            <Row>
              <DashboardKpi
                kpiTitle="Today Income Amount"
                kpiValue={todayIncomeAmount}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              />
              <DashboardKpi
                kpiTitle="Total Maalik Pending"
                kpiValue={totalMalikPending}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              />
              <DashboardKpi
                kpiTitle="Today's Maalik Pending"
                kpiValue={todaysMalikPending}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              />
              <DashboardKpi
                kpiTitle="Pending Borewell Commission"
                kpiValue={borewellComission}
                kpiIcon="fa-solid fa-comment"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
