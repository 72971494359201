import axiosInstance from "../lib/axiosInstance";

const subAdminModel = class {
  constructor() {
    this.baseUrl = "/subadmin";
  }

  async allSubAdmin() {
    const response = await axiosInstance.get(`${this.baseUrl}`);
    return response.data;
  }
  async deleteSubAdmin(id) {
    const response = await axiosInstance.delete(`${this.baseUrl}/${id}`);
    return response.data;
  }
};

export default new subAdminModel();
