import axiosInstance from "../lib/axiosInstance";

const dashboardModel = class {
  constructor() {
    this.baseUrl = "/dashboard";
  }

  async dashboardData(query = []) {
    return (
      await axiosInstance.get(
        `${this.baseUrl}/home?${new URLSearchParams(query)}`
      )
    ).data;
  }
};

export default new dashboardModel();
