import { helper } from "lib/helper";
import subAdminModel from "models/sub_admin.model";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import {
  Form,
  Col,
  FormGroup,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Input,
  Button,
} from "reactstrap";
import AddModal from "views/components/AddModal";
// core components
import TablePagination from "views/components/TablePagination";

const Rolesandpermission = () => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);

  const [isTogglePageOpen, setTogglePageOpen] = useState(false); // State for toggle page visibility

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModal1 = () => {
    setModal1(!modal1);
  };
  const [formData, setFormData] = useState({
    name: "",
    contact_no: "",
    email: "",
    city: "",
    gender: "",
    permissions: [],
  });

  const handleChangeFormData = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const location = useLocation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, contact_no, email, city, gender, permissions } = formData;
    const fareData = new FormData();
    fareData.append("name", name);
    fareData.append("contact_no", contact_no);
    fareData.append("email", email);
    fareData.append("city", city);
    fareData.append("gender", gender);
    fareData.append("permissions", JSON.stringify(permissions)); // Converting the array to a string

    // To print all the FormData entries
    for (let [key, value] of fareData.entries()) {
      console.log(`${key}: ${value}`);
    }
  };

  const handleDel = async (delId) => {
    try {
      const result = await helper.sweetalert.confirm(
        "Are you sure you want to delete subadmin",
        "info",
        true
      );
      if (result.isConfirmed) {
        const res = await subAdminModel.deleteSubAdmin(delId);
        helper.sweetalert.toast("delete successful", "success");
        getListData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getListData = async () => {
    setIsLoading(true);
    try {
      const result = await subAdminModel.allSubAdmin();
      setIsLoading(false);
      console.log(result);
      if (result) {
        setDataList(result);
        setCurrentPage(result?.meta?.current_page);
        setPerPage(result?.meta?.per_page);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListData();
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleFloatable = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Container className="pt-7 position-relative">
        {!isLoading && (
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between">
                  <h3 className="mb-0">Roles & Permissions</h3>

                  <div className="d-flex flex-row">
                    <Input className="mr-4" type="text" placeholder="Search" />

                    <Button className="px-3" onClick={toggleModal1} size="sm">
                      Add User
                    </Button>

                    <Button className="px-3" onClick={toggleModal} size="sm">
                      Assign
                    </Button>
                  </div>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="bg-dark bg-gradient text-white">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Admin Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Options</th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {dataList?.data?.length > 0 ? (
                      dataList?.data?.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem className="text-warning">
                                  <i className="fas fa-pen-to-square"></i>
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  className="text-success"
                                  onClick={toggleFloatable}
                                >
                                  <i className="fas fa-check"></i>
                                  View
                                </DropdownItem>
                                <DropdownItem className="text-warning">
                                  <i className="fas fa-xmark"></i>
                                  Unassign
                                </DropdownItem>
                                <DropdownItem
                                  className="text-danger"
                                  onClick={() => handleDel(item._id)}
                                >
                                  <i className="fas fa-trash"></i> Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {/* Uncomment the TablePagination if it's needed */}
                  <TablePagination
                    totalPages={parseInt(dataList?.meta?.total_page)}
                    currentPage={parseInt(dataList?.meta?.current_page)}
                    pageUrl="/admin/messages?"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        )}
        {dataList?.data?.map((item, index) => (
          <div
            key={index}
            className={`floatable-component position-fixed top-50 start-50 translate-middle bg-white p-3 border shadow ${
              isOpen ? "d-block" : "d-none"
            }`}
            style={{ zIndex: 1050, width: "50rem" }}
          >
            <button
              className="btn-close position-absolute end-0 top-0 m-2 bg-white border-0"
              aria-label="Close"
              onClick={toggleFloatable}
            >
              <RxCross2 className="text-xl" />
            </button>
            <div>
              <Row>
                <Col md={6}>
                  <label>Name</label>
                  <FormGroup className="border p-2 rounded-2xl">
                    {item.name}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <label>Email</label>
                  <FormGroup className="border p-2">{item.email}</FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Gender</label>
                  <FormGroup className="border p-2">{item.gender}</FormGroup>
                </Col>
                <Col md={6}>
                  <label>Contact</label>
                  <FormGroup className="border p-2">
                    {item.contact_no}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Role</label>
                  <FormGroup className="border p-2">{item.role}</FormGroup>
                </Col>
                <Col md={6}>
                  <label>City</label>
                  <FormGroup className="border p-2">{item.city}</FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Country</label>
                  <FormGroup className="border p-2">{item.country}</FormGroup>
                </Col>
                <Col md={6}>
                  <label>Permissions</label>
                  <FormGroup className="border p-2">hello</FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Wallet Amount</label>
                  <FormGroup className="border p-2">
                    {item.walletBalance}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Rolesandpermission;
